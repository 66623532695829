.banderaHeader {
    width: 60px;
    height: 40px;
  }

  .headerVolver {
    width: 70px;
    height: 50px;
    color: #ffff;    
  }

  .labelHeader{
    font-size: 15px; /* Ajusta el tamaño de la fuente según tus preferencias */
  }
  
 .sizeIcon{
  font-size: 30px; 
 }