.sidebar {
    width: 250px;
    height: 100%;
    background-color: #333;
    color: white;
    transition: transform 0.3s ease;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    overflow-y: auto;
    padding-top: 60px;
}

.sidebar.show-menu {
    transform: translateX(0);
}

.sidebar-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #222;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;
}

.hamburger-menu {
    display: none; /* Ocultar inicialmente en pantallas grandes */
}

@media (max-width: 768px) {
    .sidebar {
        transform: translateX(-250px); /* Esconder la barra lateral en pantallas pequeñas */
    }

    .sidebar.show-menu {
        transform: translateX(0);
    }

    .hamburger-menu {
        display: block; /* Mostrar el botón de hamburguesa en pantallas pequeñas */
        position: absolute;
        top: 20px;
        right: 20px;
        background: none;
        border: none;
        cursor: pointer;
        z-index: 1200;
        color: white;
    }
}
