.buttons-container {
    position: relative; /* Cambiado a relative para mantener el flujo normal del documento */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px; /* Espacio superior entre las columnas y los botones */
  }
  
  .button-wrapper {
    display: flex;
    justify-content: center;
    margin: 10px; /* Espacio entre botones */
  }
  
  @media (max-width: 768px) {
    .buttons-container {
      margin-top: 10px; /* Ajusta el espacio en pantallas más pequeñas */
    }
  }
  