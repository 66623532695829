.card:hover {
  box-shadow: 5px 10px 20px 1px rgba(255, 255, 255, 0.273) !important;
  transition: all 0.7s linear;
}

.card-body {
  padding: 2rem 0 !important;
}

.card-text {
  padding: 0.3rem 1rem;
  font-size: 1rem;
}

.overflow {
  overflow: hidden;
}

.card-img-top {
  transform: scale(0.25);
  width: 50px;
  transition: all 0.2s ease-in-out;
  border: 3px ;
  border-radius: 7px;
  padding: 5px;  
}

.card-img-top:hover {
  transform: scale(0.3);
  box-shadow: 0 0 2px 1px rgba(6, 186, 0, 0.5);
}
.modal-content {
  overflow: hidden;
}

.modal-image {
  /* Estilos para la imagen de la bandera con bordes redondeados */
  border: 1px; /* Agrega un borde sólido de 1px (puedes ajustar el grosor) */
  border-radius: 12px; /* Establece el radio de las esquinas para hacerlas redondeadas */
  padding: 5px;
  width: 100px;
}

.modal-image:hover {
  transform: scale(1.1);
  box-shadow: 0 0 2px 1px rgba(6, 186, 0, 0.5);
}

.modal-details {
  /* Estilos para los detalles del modal */
  padding: 2rem 0 !important;
}

.card-Modal {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 9px;
  margin: 9px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-modal-title {
  font-size: 1.2rem;
  //font-weight: bold;
  margin-bottom: 10px;
}

// .card-button {
//   //background-color: #012449; /* Color de fondo del botón */
//   color: #fff; /* Color de texto del botón */
//   border: none;
//   padding: 10px 15px; /* Ajusta el relleno según tus preferencias */
//   border-radius: 5px; 
//   cursor: pointer;
//   font-size: 1rem;
//   transition: background-color 0.2s ease-in-out; /* Efecto de transición de color al pasar el cursor */
// }

