.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  color: #2796cf;
  font-size: 0.8rem; /* Tamaño de fuente más pequeño */
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #2796cf; /* Grosor de la línea */
  margin: 0 35px; /* Espaciado más pequeño */
}

.separator span {
  font-weight: normal; /* Peso de la fuente más ligero */
  font-size: 0.8rem; /* Tamaño de fuente ajustado */
}
